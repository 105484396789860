
$(window).on('scroll', function () {
  var docHeight = $(document).innerHeight(), //ドキュメントの高さ
    windowHeight = $(window).innerHeight(), //ウィンドウの高さ
    pageBottom = docHeight - windowHeight; //ドキュメントの高さ - ウィンドウの高さ
  // if (pageBottom <= $(window).scrollTop()) {
  //   //ウィンドウの一番下までスクロールした時に実行
  //   // $("body").removeClass("is-scrolled");
  //   $("body").addClass("is-scroll-bottom");
  // } else {
  //   $("body").removeClass("is-scroll-bottom");
  // }
  
});
