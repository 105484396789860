import swiper from "swiper";

if ($(".top-hero").length) {
  var hero = new swiper('.top-hero__contents', {
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true,
    loop: true,
    effect: "fade",
    speed: 1200,
    slideToClickedSlide: false,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1200,
    pagination: {
      el: ".top-hero__pager",
      clickable: true
    },
  });
}

if ($(".l-banner").length) {
  var banner = new swiper('.l-banner-slider', {
    slidesPerView: 3,
    spaceBetween: 20,
    // centeredSlides: true,
    loop: true,
    // effect: "fade",
    speed: 1200,
    slideToClickedSlide: false,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1200,
    navigation: {
      prevEl: '.l-banner-slider__prev',
      nextEl: '.l-banner-slider__next',
    },
    breakpoints: {
      1024: {
        slidesPerView: 2,
      },
      700: {
        slidesPerView: 1,
      }
    },
  });
}

if ($(".l-slideShow").length) {
  // const theWrapper = document.getElementById('swiperWrapper');
  var slider01 = new swiper(".l-slideShow", {
    allowTouchMove: false,
    freeMode: true,
    loop: true,
    loopedSlides: 4,
    slidesPerView: 3.5,
    spaceBetween: 0,
    speed: 20000,
    autoplay: {
      delay: 1,
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2.5,
      }
    },
  });
}

if ($(".-slider--01").length) {
  var contents01 = new swiper('.-slider--01', {
    slidesPerView: 1.73,
    spaceBetween: 40,
    centeredSlides: true,
    loop: true,
    speed: 1200,
    slideToClickedSlide: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1200,
    pagination: {
      // el: ".top-hero__pager",
      clickable: true
    },
    breakpoints: {
      768: {
        spaceBetween: 20,
        slidesPerView: 1.4
      }
    },
  });
}

if ($(".-slider--02").length) {
  var contents02 = new swiper('.-slider--02', {
    slidesPerView: 1.73,
    spaceBetween: 40,
    centeredSlides: true,
    loop: true,
    speed: 1200,
    slideToClickedSlide: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1200,
    pagination: {
      // el: ".top-hero__pager",
      clickable: true
    },
    breakpoints: {
      768: {
        spaceBetween: 20,
        slidesPerView: 1.4
      }
    },
  });
}

if ($(".-slider--03").length) {
  var contents03 = new swiper('.-slider--03', {
    slidesPerView: 1.73,
    spaceBetween: 40,
    centeredSlides: true,
    loop: true,
    speed: 1200,
    slideToClickedSlide: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1200,
    pagination: {
      // el: ".top-hero__pager",
      clickable: true
    },
    breakpoints: {
      768: {
        spaceBetween: 20,
        slidesPerView: 1.4
      }
    },
  });
}

if ($(".-slider--04").length) {
  var contents04 = new swiper('.-slider--04', {
    slidesPerView: 1.73,
    spaceBetween: 40,
    centeredSlides: true,
    loop: true,
    speed: 1200,
    slideToClickedSlide: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1200,
    pagination: {
      // el: ".top-hero__pager",
      clickable: true
    },
    breakpoints: {
      768: {
        spaceBetween: 20,
        slidesPerView: 1.4
      }
    },
  });
}
